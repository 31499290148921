import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "gatsby";
import styled from "styled-components";
import Wrapper from "./Visual/Wrapper";
import LanguageToggle from "./LanguageToggle";
import { excludeNavPaths, defaultLanguage } from "../../config";
import Logo2 from "../svg/logo.svg";

const MAX_WIDTH_FOR_BURGER_MENU = 992;

const NavWrapper = styled.div`
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    z-index: 200;
`;
const StyledNav = styled.nav`
    background-color: ${props =>
        !props.transparentAtTop || props.offset > 0 || props.isOpen
            ? `rgba(0, 0, 0, 0.85)`
            : `transparent`};
    transition: background-color 0.2s ease-in;
`;
const NavCenter = styled.div`
    align-items: center;
    @media screen and (min-width: ${MAX_WIDTH_FOR_BURGER_MENU}px) {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
`;

const NavHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: ${MAX_WIDTH_FOR_BURGER_MENU}px) {
        padding: 0.5rem 0;
    }
`;
const BurgerMenuButton = styled.button`
    background: transparent;
    border: none;
    outline: none;
    color: var(--mainWhite);
    font-size: 1.5rem;

    &:hover {
        cursor: pointer;
    }
    @media screen and (min-width: ${MAX_WIDTH_FOR_BURGER_MENU}px) {
        display: none;
    }
`;
const NavLink = styled.ul`
    list-style-type: none;
    transition: var(--mainTransition);
    height: ${props => (props.expanded ? `0` : `250px`)};
    overflow: hidden;
    margin-bottom: 0px;
    & li {
        list-style-type: none;
        margin-left: 0;
    }

    & a {
        display: block;
        padding: 0 0 0 2.5rem;
        text-decoration: none;
        text-transform: none;
        color: var(--mainWhite);
        transition: var(--mainTransition);
        font-weight: bold;
        font-size: 1.3rem;
    }

    & a:hover {
        color: var(--primaryColor);
    }

    @media screen and (min-width: ${MAX_WIDTH_FOR_BURGER_MENU}px) {
        height: auto;
        display: flex;
    }
`;

const Navbar = ({ transparentAtTop, langCode, navLinks, mdxFields }) => {
    const [isOpen, setNav] = useState();
    const [offset, setOffset] = useState(0);
    const toggleNav = () => {
        setNav(isOpen => !isOpen);
    };

    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset);
        };
        window.onresize = () => {
            setNav(isOpen =>
                isOpen && window.outerWidth > MAX_WIDTH_FOR_BURGER_MENU
                    ? !isOpen
                    : isOpen
            );
        };
    }, []);

    return (
        <NavWrapper>
            <StyledNav
                offset={offset}
                isOpen={isOpen}
                transparentAtTop={transparentAtTop}
            >
                <Wrapper>
                    <NavCenter>
                        <NavHeader>
                            <Link
                                to={
                                    langCode === defaultLanguage
                                        ? `/`
                                        : `/${langCode}`
                                }
                            >
                                <Logo2 />
                            </Link>
                            <BurgerMenuButton
                                type="button"
                                onClick={toggleNav}
                                aria-label="Menu button"
                            >
                                <FaBars />
                            </BurgerMenuButton>
                        </NavHeader>
                        <NavLink expanded={!isOpen}>
                            {navLinks.edges
                                .filter(
                                    edge =>
                                        !excludeNavPaths.some(path =>
                                            path
                                                .replace("**", langCode)
                                                .match(edge.node.fields.slug)
                                        )
                                )
                                .filter(
                                    edge =>
                                        !new RegExp("(|en)/testimonials/").test(
                                            edge.node.fields.slug
                                        ) &&
                                        !new RegExp("(|en)/products/").test(
                                            edge.node.fields.slug
                                        ) &&
                                        !new RegExp("(|en)/eula").test(
                                            edge.node.fields.slug
                                        ) &&
                                        !new RegExp(
                                            "(|en)/privacy-policy"
                                        ).test(edge.node.fields.slug)
                                )
                                .map((edge, index) => {
                                    const { fields } = edge.node;
                                    return (
                                        <li key={index}>
                                            <Link to={fields.slug}>
                                                {fields.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            <LanguageToggle
                                currentLang={langCode}
                                slug={mdxFields.slug}
                            />
                        </NavLink>
                    </NavCenter>
                </Wrapper>
            </StyledNav>
        </NavWrapper>
    );
};

export default Navbar;
