import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from "gatsby";
import Navbar from "./Navbar";
import Footer from "./Footer";
import GlobalStyle from "./GlobalStyle";
import Wrapper from "./Visual/Wrapper";
import Grid from "./Visual/Grid";
import SEO from "./SEO";
import { StaticImage } from "gatsby-plugin-image";

const shortcodes = { Link, Wrapper, Grid, StaticImage };

export default function Layout({ children, data, location }) {
    const { collection, nonLocalizedSlug } = data.mdx.fields;
    const { metaTitle, metaDescription } = data.mdx.frontmatter;
    const regex = new RegExp(
        "(|en)/(news|products|privacy-policy|eula|support|testimonials)/*[a-z]*"
    );
    if (!data) {
        return children;
    }
    return (
        <>
            <SEO
                metaTitle={metaTitle}
                metaDescription={metaDescription}
                lang={collection}
                nonLocalizedSlug={nonLocalizedSlug}
                siteUrl={data.site.siteMetadata.siteUrl}
            />
            <main>
                <GlobalStyle />
                <Navbar
                    transparentAtTop={!regex.test(data.mdx.fields.slug)}
                    langCode={collection}
                    navLinks={data.allMdx}
                    mdxFields={data.mdx.fields}
                />
                <MDXProvider components={shortcodes}>
                    <MDXRenderer lang={collection} location={location}>
                        {data.mdx.body}
                    </MDXRenderer>
                </MDXProvider>
                <Footer lang={collection} />
            </main>
        </>
    );
}

export const pageQuery = graphql`
    query($id: String!, $language: String) {
        site {
            siteMetadata {
                title
                siteUrl
            }
        }
        mdx(fields: { id: { eq: $id } }) {
            fields {
                id
                title
                slug
                collection
                nonLocalizedSlug
            }
            frontmatter {
                metaTitle
                metaDescription
            }
            body
            tableOfContents
            parent {
                ... on File {
                    relativePath
                }
            }
        }
        allMdx(filter: { fields: { collection: { eq: $language } } }) {
            edges {
                node {
                    fields {
                        slug
                        title
                        collection
                    }
                }
            }
        }
    }
`;
